html {
    font-family: "Open Sans", sans-serif;

    a {
        color: unset;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            color: unset;
            text-decoration: none;
        }
    }

    background-color: #ebb7ca;
    display: flex;
    justify-content: center;

    body {
        display: flex;
        flex-direction: column;
        max-width: 75rem;
        text-align: center;

        .copyright {
            margin-top: 3rem;
            font-weight: 700;
        }



        //         h1,
        //         p {
        //             flex-basis: 100%;
        //             text-align: center;
        //         }

        //         h1 {
        //             font-family: "Pacifico", cursive;
        //             font-size: 8rem;
        //             line-height: 1;
        //             font-size: 5rem;

        //             @media screen and(min-width: 56.25rem) {
        //                 font-size: 8rem;
        //             }
        //         }

        //         p.tagline {
        //             font-weight: 700;
        //             font-size: 1.25rem;
        //             margin-bottom: 3rem;


        //             @media screen and(min-width: 56.25rem) {
        //                 font-size: 1.8rem;
        //             }
        //         }
        //     }

        //     main {
        //         border-top: 0.3125rem solid black;
        //         padding-top: 3rem;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         flex-direction: column;

        //         .contactMe {
        //             background: url('./images/badge.svg') no-repeat;
        //             background-size: cover;
        //             position: absolute;
        //             height: 5rem;
        //             width: 5rem;
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             transform: rotate(20deg);
        //             font-weight: 700;
        //             cursor: pointer;
        //             transition: transform 0.3s;
        //             top: 1rem;
        //             right: 1rem;
        //             color: white;
        //             font-size: 1rem;
        //             z-index: 1;

        //             @media screen and(min-width: 56.25rem) {
        //                 top: 2rem;
        //                 right: 2rem;
        //                 height: 10rem;
        //                 width: 10rem;
        //                 font-size: 2rem;
        //             }

        //             &:hover {
        //                 transform: scale(1.1);
        //                 transform: rotate(10deg);
        //             }
        //         }

        //         .Categories {
        //             flex-grow: 1;

        //             button {
        //                 background: white;
        //                 color: black;
        //                 font-size: 1.875rem;
        //                 margin: 0 0.625rem;
        //                 border: none;
        //                 cursor: pointer;

        //                 &.active {
        //                     background: black;
        //                     color: white;
        //                 }

        //                 &:hover {
        //                     text-decoration: underline;
        //                 }

        //                 &:focus,
        //                 &:active {
        //                     outline: none;
        //                     border: none;
        //                 }
        //             }
        //         }

        //         .ProjectPage {
        //             display: flex;
        //             flex-direction: column;
        //             align-items: center;
        //             max-width: 100rem;

        //             img {
        //                 max-width: 100%;
        //                 width: 100%;
        //             }

        //             iframe,
        //             figure {
        //                 width: 80%;
        //                 margin: 4rem auto;
        //                 display: block;
        //             }

        //             &__Description {
        //                 max-width: 50rem;

        //                 img {
        //                     max-width: 37.5rem;
        //                 }
        //             }
        //         }

        //         .copyright {
        //             margin-top: 3rem;
        //             font-weight: 700;
        //         }

        //         .Projects {
        //             display: flex;
        //             flex-wrap: wrap;
        //             width: 100%;
        //             justify-content: center;
        //             margin-top: 3rem;
        //             max-width: 100rem;

        //             &>.Project {
        //                 width: 100%;
        //                 height: 18.75rem;
        //                 background-repeat: no-repeat;
        //                 background-size: cover;
        //                 margin: 1rem;
        //                 font-weight: 600;
        //                 position: relative;
        //                 background-position: center;

        //                 @media screen and(min-width: 25.625rem) {
        //                     width: calc(50% - 2rem);
        //                 }

        //                 @media screen and(min-width: 56.25rem) {
        //                     width: calc(33% - 2rem);
        //                 }

        //                 h2 {
        //                     display: none;
        //                     left: 0;
        //                     bottom: 0;
        //                     right: 0;
        //                     position: absolute;
        //                     padding: 0;
        //                     background: rgba($color: #000000, $alpha: 0);
        //                     color: white;
        //                     text-align: center;
        //                     transition: background linear 0.2;
        //                 }

        //                 &:hover {
        //                     h2 {
        //                         display: block;
        //                         background: rgba($color: #000000, $alpha: 0.3);
        //                     }
        //                 }

        //                 a {
        //                     height: 100%;
        //                     width: 100%;
        //                     position: relative;
        //                     background: rgba(0, 0, 0, 0.4);
        //                     display: block;
        //                     transition: background 0.1s linear;

        //                     &:hover {
        //                         background: rgba(0, 0, 0, 0);
        //                     }
        //                 }
        //             }
        //         }

        //         .Contact {
        //             .EmailForm {
        //                 display: flex;
        //                 flex-direction: column;

        //                 >* {
        //                     margin: 1rem;
        //                 }

        //                 >label {
        //                     font-weight: 700;
        //                     font-size: 1.25rem;
        //                 }

        //                 >input,
        //                 >textarea {
        //                     padding: 1rem;
        //                     border: solid black 0.25rem;
        //                     font-size: 1.6rem;
        //                     width: calc(100% - 2rem);
        //                 }


        //                 >button {
        //                     padding: 1rem;
        //                     font-size: 1.5rem;
        //                     background: black;
        //                     color: white;
        //                     font-weight: 700;
        //                     border: solid black 0.15rem;
        //                     cursor: pointer;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}